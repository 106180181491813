import React, { Fragment } from "react";
import Sticky from "react-stickynode";
import { graphql } from "gatsby";
import { ThemeProvider } from "styled-components";

import { theme } from "../common/theme";
import { ResetCSS } from "../assets/css/style";
import { ContentWrapper, GlobalStyle } from "../containers/saas.style";
import Navbar from "../containers/common/Navbar";
import Footer from "../containers/common/Footer";
import { DrawerProvider } from "../contexts/DrawerContext";
import { Container, SEO } from "@components/index";
import { BlogSection } from "@containers/blog";

const browser = typeof window !== "undefined" && window;

const BlogPage = ({ data }) => {
  const posts = data.allMarkdownRemark.nodes

  return browser && (
    <ThemeProvider theme={theme}>
      <Fragment>
        <SEO title="Blog" />
        <ResetCSS />
        <GlobalStyle />
        <ContentWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar notMainPage />
            </DrawerProvider>
          </Sticky>
          <Container>
            <BlogSection posts={posts} />
          </Container>
          <Footer notMainPage />
        </ContentWrapper>
      </Fragment>
    </ThemeProvider>
  );
};

export default BlogPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }, filter: {fileAbsolutePath: { regex: "/(blog)/"}}) {
      nodes {
        excerpt
        frontmatter {
          slug
          date(formatString: "MMMM DD, YYYY")
          title
          description
          author {
            name
            bio
            profilepicture {
              childImageSharp {
                gatsbyImageData(width: 30)
              }
            }
          }
          category
          tags
          featuredImage {
            src {
              childImageSharp {
                gatsbyImageData(width: 720)
              }
            }
            alt
          }
        }
      }
    }
  }
`
