import React from 'react'
import { Link } from 'gatsby'
import Img from "gatsby-image"
import { GatsbyImage } from "gatsby-plugin-image"

import { Post } from 'common/types'

type Props = {
    post: Post,
    excerpt: string,
}

const PostCard = (props: Props) => {
    const { post, excerpt } = props;

    return (
        <Link to={`/${post.slug}`} className="post-card">
            <header className="post-card-header">
                {post.featuredImage &&
                    <GatsbyImage className="post-card-image"
                        image={post.featuredImage.src.childImageSharp.gatsbyImageData}
                        alt={post.featuredImage.alt}
                  />}
                {post.category && <div className="post-card-tags">{post.category}</div>}
                <h2 className="post-card-title">{post.title}</h2>
            </header>
            <section className="post-card-excerpt">{excerpt}</section>
            <footer className="post-card-footer">
                <div className="post-card-footer-left">
                    <div className="post-card-avatar">
                        <GatsbyImage
                            className="author-profile-image"
                            image={post.author.profilepicture.childImageSharp.gatsbyImageData}
                            alt={post.author.name}
                        />
                    </div>
                    <span>{ post.author.name }</span>
                </div>
                <div className="post-card-footer-right">
                    <div>{post.date}</div>
                </div>
            </footer>
        </Link>
    )
}

export default PostCard;

