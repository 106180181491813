import React from 'react';
import Img from "gatsby-image"
import Icon from "react-icons-kit";
import { GatsbyImage } from "gatsby-plugin-image"
import { ic_email } from "react-icons-kit/md/ic_email";
import { linkedin } from "react-icons-kit/fa/linkedin";

import { Button, Container, Logo, Text } from '@components/index';
import { BlogPostWrapper } from './blogPost.style';
import { Post } from 'common/types';
import LogoImage from '../../../assets/image/favicon.png';
import { DeviceContext } from 'contexts/DeviceProvider';

type Props = {
    post: Post,
    html: any
}

const BlogPostSection = (props: Props) => {
    const isSmallScreen = React.useContext(DeviceContext);
    const { post, html } = props;

    const getAuthorBio = (authorId: string) => {
        if (authorId === 'alexandru') {
            return <div style={{ color: 'rgb(60, 72, 78)' }}>Alexandru Mogic is a <b>software engineer</b> from Romania currently <b>digitizing finance</b> for companies based in Norway, Oslo. He also operates as the <b>co-founder</b> and <b>technical advisor</b> of Talent Swipe <i>helping IT businesses grow by providing them the best talent on the market</i>.</div>;
        } else if (authorId === 'cristina') {
            return <div style={{ color: 'rgb(60, 72, 78)'}}>Cristina Lapadatu is the <b>founder</b> and <b>talent seeker</b> at Talent Swipe. With more than 5 years of experience in <b>technical recruitment</b>, she <i>helps IT organisations grow by guiding talent into exciting careers</i>.</div>
        } else if (authorId === 'iemima') {
            return <div style={{ color: 'rgb(60, 72, 78)'}}>Iemima Muresan is a <b>tech talent seeker</b> at Talent Swipe. She helps IT organisations grow by guiding talent into exciting careers.</div>
        } else {
            return null;
        }
    }

    return (
        <BlogPostWrapper>
            <Container>
                <h1 className="content-title">{post.title}</h1>
                <div className="post-card-footer">
                    <div className="post-card-footer-left">
                        <div className="post-card-avatar">
                            <GatsbyImage
                                className="author-profile-image"
                                image={post.author.profilepicture.childImageSharp.gatsbyImageData}
                            />
                        </div>
                        <div style={{ color: 'black', fontSize: '1rem' }}>{post.author.name}</div>
                    </div>
                    <div className="post-card-footer-right">
                        <div>{`· ${post.date} · ${post.readingTime}`}</div>
                    </div>
                </div>
                <section className="post-full-content">
                    <section
                        className="content-body"
                        dangerouslySetInnerHTML={{ __html: html }}
                    />
                </section>
                <div style={{ border: '1px solid #e3e9ed', borderColor: '0px 2px 2.74px 1.26px #e3e9ed', marginTop: 50, padding: 20, borderRadius: 12 }}>
                    <div className="post-card-footer-bottom">
                        <div className="post-card-avatar-big">
                            <GatsbyImage
                                className="author-profile-image"
                                image={post.author.profilepicture.childImageSharp.gatsbyImageData}
                            />
                        </div>
                        <div style={{ flexDirection: 'column' }}>
                            <div style={{ color: 'black', fontSize: '1.6rem', fontWeight: 'bold', marginBottom: 4 }}>About the author</div>
                            {getAuthorBio(post.author.id)}
                            <div>
                                <a target="_blank" rel="noopener noreferrer" href={post.author.linkedin}>
                                    <Button icon={<Icon icon={linkedin} size={26} />} style={{ marginRight: 8, marginTop: 8 }} />
                                </a>
                                <a href={`mailto:${post.author.mail}`}>
                                <Button icon={<Icon icon={ic_email} size={26} />} />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div style={{ backgroundColor: '#f6f7fd', margin: -20, padding: 8, paddingLeft: 20, paddingRight: 20, display: 'flex', flexDirection: isSmallScreen ? 'column' : 'row', marginTop: 20, justifyContent: 'space-between', borderBottomLeftRadius: 12, borderBottomRightRadius: 12 }}>
                        <div style={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                <Logo src={LogoImage} style={{ width: isSmallScreen ? '80px' : '100px', flex: 1 }} />
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', padding: 20, marginTop: 10, justifyContent: 'center', flex: 1 }}>
                                <Text content="Stay connected" lineHeight="0" />
                                <Text content="with us" lineHeight="0"  />
                                <Text content="on social media" lineHeight="0"  />
                            </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', flex: 1, justifyContent: 'flex-end' }}>
                            <div style={{ backgroundColor: 'white', flexDirection: 'column', margin: 10, padding: 10, borderRadius: 12, alignItems: 'center', marginRight: 12 }}>
                                <Text content="Facebook" style={{ textAlign: 'center' }} />
                                <iframe src="https://www.facebook.com/plugins/like.php?href=https%3A%2F%2Fwww.facebook.com%2Ftalentswipe.careers&width=100&layout=box_count&action=like&size=small&share=false&height=65&appId=733963280578337" width="100" height="65" style={{ border: 'none', overflow: 'hidden' }} scrolling="no" frameBorder="0" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
                            </div>
                            <div style={{ backgroundColor: 'white', flexDirection: 'column', margin: 10, padding: 10, borderRadius: 12, alignItems: 'center' }}>
                                <Text content="LinkedIn" style={{ textAlign: 'center' }} />
                                <iframe src="https://www.linkedin.com/pages-extensions/FollowCompany?id=69802562&counter=bottom" width="100" height="65" seamless={true} scrolling="no" frameBorder="0" />
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </BlogPostWrapper>
    )
}

export default BlogPostSection;
