import { PostMarkdown } from 'common/types';
import React from 'react';
import { Container, Text } from '@components/index';
import PostCard from './components/PostCard';
import { BlogSectionWrapper } from './blogSection.style';

type Props = {
  posts: Array<PostMarkdown>
}

const BlogSection = ({ posts }: Props) => {
    return (
        <BlogSectionWrapper>
            <Container noGutter>
                <section className="blog-header">
                    <Text content="Latest news on Talent Swipe" style={{ fontSize: "2.2em", fontWeight: 700, alignSelf: 'center' }} />
                    <Text content="Check out our latest blog posts and updates" style={{ alignSelf: 'center', color: 'gray' }} />
                </section>
                <section className="post-feed">
                    {posts.map((entry, key) => (
                        <PostCard key={key} post={entry.frontmatter} excerpt={entry.excerpt} />
                    ))}
                </section>
            </Container>
        </BlogSectionWrapper>
    )
}

export default BlogSection;